import { Button, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteCache } from "../../services/cache";
import { getColor } from "../../styles/colors";
import { FaHome } from "react-icons/fa";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const home = location.pathname === "/";
  return (
    <Flex
      width="100%"
      padding="10px 15px"
      justifyContent="flex-end"
      alignItems="center"
      backgroundColor={getColor("CAPE")}
      gap="10px"
    >
      {!home && (
        <Button
          leftIcon={<FaHome />}
          borderRadius="0px"
          backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
          color="white"
          _hover={{ backgroundColor: getColor("TOXIC_ZOE_LAVERNE") }}
          onClick={() => {
            deleteCache("userFilter");
            navigate("/sessions");
          }}
        >
          Página Inicial
        </Button>
      )}

      {!home && (
        <Button
          backgroundColor="red"
          onClick={() => {
            deleteCache("user");
            navigate("/");
          }}
          borderRadius="0px"
        >
          Sair
        </Button>
      )}
    </Flex>
  );
}
