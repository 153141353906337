import api from "./api";
import fastApi from "./fastApi";

export const createSession = async (props) => {
  try {
    const response = await api.post("/api/placebet/create/", props);

    return response.data;
  } catch (err) {
    return "ERROR";
  }
};

export const fetchSessions = async () => {
  try {
    const data = await api
      .get("/api/placebet/session/")
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const fetchSession = async (id) => {
  try {
    const data = await api
      .get(`/api/placebet/session/${id}/`)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deleteSession = async (id) => {
  const response = await api.delete(`/api/placebet/session/${id}/`);

  return response.data;
};

export const fetchBalance = async (id) => {
  try {
    const data = await api
      .get(`/api/placebet/session/${id}/balance/`)
      .then((response) => response.data.balance);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const fetchActive = async (id) => {
  try {
    const data = await api
      .get(`/api/placebet/session/${id}/active/`)
      .then((response) => response.data.active);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const fetchOpenBets = async (id) => {
  try {
    const data = await api
      .get(`/api/placebet/session/${id}/openbets/`)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const fetchSettledBets = async (id) => {
  try {
    const data = await api
      .get(`/api/placebet/session/${id}/settledbets/`)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const createSessionWithUser = async (props) => {
  try {
    const data = await fastApi
      .post("/session", props)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const fetchSessionWithUser = async () => {
  try {
    const data = await fastApi
      .get("/session")
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const editSessionWithUser = async (id, props) => {
  try {
    const data = await fastApi
      .put(`/session/${id}`, props)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deleteSessionWithUser = async (id) => {
  try {
    const data = await fastApi
      .delete(`/session/${id}`)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};
